import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: "0 40px",
		[theme.breakpoints.down('sm')]: {
			padding: "0 16px",

		},
		[theme.breakpoints.up(2130)]: {
			padding: '0 20%'
		}
	},
	numberContianer: {
		//TABLET
		[theme.breakpoints.down('xs')]: {
			width: '100%'

		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			width: '100%',
			justifyContent: 'center',
			display: 'flex',
			marginBottom: '22px'

		},
	},
	image: {
		width: 408,
		height: 466,
		[theme.breakpoints.down('sm')]: {
			width: 343,
			height: 310,
		},
		[theme.breakpoints.between(1024, 1440)]: {
			width: 343,
			height: 310,
		}
	},
	number: {
		width: 104,
		height: 116,
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			width: "37.07px",
			height: "50.6px"
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			width: "37.07px",
			height: "72.6px"

		},
	},
	textContainer: {
		width: 744,
		marginLeft: 40,
		marginTop: 10,

		"&:nth-child(2)": {
			marginTop: 0,
			marginLeft: 0,

		},
		"&:nth-child(3)": {
			marginTop: 0,
			marginLeft: 20,

		},
		"&:nth-child(4)": {
			marginLeft: 8,

		},
		[theme.breakpoints.down('xs')]: {
			width: "100%",
			"&:nth-child(3)": {
				marginTop: 0,
				marginLeft: 0,

			},
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			padding: "0 32px",

		},
	},
	stepTitle: {
		fontSize: 40,
		lineHeight: '36px',
		fontWeight: 'bold',
		marginBottom: 24,
		width: 800,
		//TABLET
		[theme.breakpoints.down('xs')]: {
			fontSize: 32,
			lineHeight: '40px',
			width: "100%"
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			textAlign: 'center',
			width: '100%'
		},
	},
	stepDescription: {
		fontSize: 24,
		lineHeight: '44px',
		letterSpacing: '0.15px',
		[theme.breakpoints.down('sm')]: {
			fontSize: 18,
			lineHeight: '32px',
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			textAlign: 'center',
		},
	},
	order1: {
		[theme.breakpoints.down('sm')]: {
			order: 1
		}
	},
	order2: {
		[theme.breakpoints.down('sm')]: {
			order: 2
		}
	},
	order3: {
		[theme.breakpoints.down('sm')]: {
			order: 3
		}
	},
	itemStep: {
		[theme.breakpoints.between('sm', 'md')]: {

		}
	}
}))