import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./Steps.styles";
import clsx from "clsx";

export function Steps() {
	const classes = useStyles()
	return (
		<Grid container direction="column" className={classes.root} id="steps">
			<Grid
				item
				container
				alignItems="center"
				justifyContent="space-evenly"
				data-sal="slide-up"
				data-sal-delay="0"
				data-sal-duration="1000"
				data-sal-easing="ease"
			>
				<Grid
					item
				>
					<img className={classes.image} alt="Completa nuestro formulario" src="/assets/images/paso-1.svg" />
				</Grid>
				<Grid
					item
					className={classes.numberContianer}

				>
					<img className={classes.number} alt="" src="/assets/images/1.svg" />
				</Grid>
				<Grid
					item
					className={classes.textContainer}

				>
					<Typography className={classes.stepTitle}>
						Completa nuestro formulario
					</Typography>
					<Typography className={classes.stepDescription}>
						Ingresa a la plataforma de Kredi e inicia tu aplicación.
						Completa nuestro formulario en línea, es importante llenar todos los campos para un buen resultado de precalificación.
					</Typography>
				</Grid>
			</Grid>

			<Grid
				item
				container
				alignItems="center"
				justifyContent="space-evenly"
				data-sal="slide-up"
				data-sal-delay="0"
				data-sal-duration="1000"
				data-sal-easing="ease"
			>
				<Grid
					item
					className={clsx(classes.order2, classes.numberContianer)}

				>
					<img className={classes.number} alt="" src="/assets/images/2.svg" />
				</Grid>
				<Grid
					item
					className={clsx(classes.textContainer, classes.order3)}

				>
					<Typography className={classes.stepTitle}>
						Recibe asesoría de un experto hipotecario
					</Typography>
					<Typography className={classes.stepDescription}>
						Un asesor experto hipotecario de Kredi se pondrá en contacto contigo para darle seguimiento a tu proceso. Te hará llegar un listado con los documentos que requiere para tu solicitud.
					</Typography>
				</Grid>
				<Grid
					item
					className={classes.order1}

				>
					<img className={classes.image} alt="Recibe asesoría de un experto hipotecario" src="/assets/images/paso-2.svg" />
				</Grid>

			</Grid>


			<Grid
				item
				container
				alignItems="center"
				justifyContent="space-evenly"
				data-sal="slide-up"
				data-sal-delay="0"
				data-sal-duration="1000"
				data-sal-easing="ease"
			>
				<Grid
					item
				>
					<img className={classes.image} alt="" src="/assets/images/paso-3.svg" />
				</Grid>
				<Grid
					item
					className={classes.numberContianer}
				>
					<img className={classes.number} alt="Autorización" src="/assets/images/3.svg" />
				</Grid>
				<Grid
					item
					className={classes.textContainer}

				>
					<Typography className={classes.stepTitle}>
						Autorización
					</Typography>
					<Typography className={classes.stepDescription}>
						Una vez que contemos con toda tu información, evaluaremos tu solicitud, recibirás tu respuesta en menos de 48 horas.
					</Typography>
				</Grid>
			</Grid>

			<Grid
				item
				container
				alignItems="center"
				justifyContent="space-evenly"
				data-sal="slide-up"
				data-sal-delay="0"
				data-sal-duration="1000"
				data-sal-easing="ease"
			>
				<Grid
					item
					className={clsx(classes.order2, classes.numberContianer)}

				>
					<img className={classes.number} alt="" src="/assets/images/4.svg" />
				</Grid>
				<Grid
					item
					className={clsx(classes.textContainer, classes.order3)}

				>
					<Typography className={classes.stepTitle}>
						Avalúo y notaría
					</Typography>
					<Typography className={classes.stepDescription}>
						Kredi asignará una unidad de valuación y una notaría que se encuentre dentro del padrón, esta unidad se encargará de practicar el avalúo de la casa o departamento.
					</Typography>
				</Grid>
				<Grid
					item
					className={classes.order1}

				>
					<img className={classes.image} alt="Avalúo y notaría" src="/assets/images/paso-4.svg" />
				</Grid>
			</Grid>

			<Grid
				item
				container
				alignItems="center"
				justifyContent="space-evenly"
				data-sal="slide-up"
				data-sal-delay="0"
				data-sal-duration="1000"
				data-sal-easing="ease"
			>
				<Grid
					item

				>
					<img className={classes.image} alt="Cierre y firma" src="/assets/images/paso-5.svg" />
				</Grid>
				<Grid
					item
					className={classes.numberContianer}

				>
					<img className={classes.number} alt="" src="/assets/images/5.svg" />
				</Grid>
				<Grid
					item
					className={classes.textContainer}

				>
					<Typography className={classes.stepTitle}>
						Cierre y firma
					</Typography>
					<Typography className={classes.stepDescription}>
						Kredi te informará sobre el cierre de cifras que consta de las condiciones del crédito. Firma y disfruta del hogar de tus sueños.					</Typography>
				</Grid>
			</Grid>

		</Grid >
	)
}