import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		backgroundImage: `url("/assets/images/hero-how-it-works.webp")`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',

		[theme.breakpoints.down('sm')]: {
			backgroundSize: "cover",
			backgroundImage: `url("/assets/images/hero-how-it-works-mobile.jpg")`,
			padding: '0 16px'

		},
		[theme.breakpoints.between('sm', 'md')]: {
			backgroundSize: "cover",
			backgroundImage: `url("/assets/images/hero-how-it-works-mobile.jpg")`,
			padding: '0 32px'

		},
		minHeight: "calc(100vh - 72px)",
		maxHeight: "calc(100vh - 72px)",
		position: 'relative',

	},
	title: {
		fontSize: 60,
		lineHeight: '76px',
		color: 'white',
		//marginTop: 275,
		fontWeight: 'bold',
		marginBottom: 16,
		[theme.breakpoints.down('sm')]: {
			marginTop: -70,
			textAlign: 'center',
			fontSize: 40,
			lineHeight: '48px',
		}
	},
	subtitle: {
		fontSize: 24,
		lineHeight: '32px',
		color: 'white',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			fontSize: 20,
			lineHeight: '30px',
		}
	},
	buttonContainer: {
		position: 'absolute',
		bottom: 44,
	},
	buttonDown: {
		background: 'rgba(255, 255, 255, 0.5)',
		width: 80,
		height: 80,
		padding: 0,
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		// marginTop: 252,
		// marginBottom: 42,
		[theme.breakpoints.down('sm')]: {
			width: 56,
			minWidth: 56,
			height: 56,
			//marginTop: 245,

		}
	},
	arrowDown: {
		transform: "rotate(-90deg)",
		fontSize: 28,
		marginTop: '-9px',
		[theme.breakpoints.down('sm')]: {
			fontSize: 23,

		}
	}
}))