import React from "react"
import { FAQ, Footer } from "../components"
import { HeroHowItsWork, Steps } from "../components/HowItWorks"
import { Layout } from "../layout"
function ComoFunciona({ location }: any) {
	return (
		<Layout title="Kredi | ¿Cómo Funciona?" location={location}>
			<HeroHowItsWork />
			<Steps />
			<FAQ />
			<Footer title="Un crédito hipotecario cómo nunca antes se había visto" />
		</Layout>
	)
}

export default ComoFunciona