import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./Hero.styles";
import { ArrowBackIos as ArrowIcon } from "@material-ui/icons";
import { Button } from "../../";
import { navigate } from "gatsby";
import clsx from "clsx";

export function HeroHowItsWork() {
	const classes = useStyles()
	return (
		<Grid container direction="column" alignItems="center" justifyContent="center" className={classes.root}>
			<Grid
				item
				data-sal="slide-down"
				data-sal-delay="0"
				data-sal-duration="1000"
				data-sal-easing="ease"
			>
				<Typography className={classes.title}>¿Cómo funciona nuestro proceso?</Typography>
			</Grid>
			<Grid
				item
				data-sal="slide-down"
				data-sal-delay="0"
				data-sal-duration="1000"
				data-sal-easing="ease"
			>
				<Typography className={classes.subtitle}>Obtener un crédito con nosotros es muy sencillo. Aquí te mostramos porqué.</Typography>
			</Grid>
			<Grid item className={classes.buttonContainer}>
				<Button onClick={() => navigate("#steps")} className={clsx(classes.buttonDown, "bounce-top-infinity")}>
					<ArrowIcon className={classes.arrowDown} />
				</Button>
			</Grid>

		</Grid>
	)
}